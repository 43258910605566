<template>
    <div>
        <div v-if="selectedAutomationType.name == AUTOMATION.MARKETING_AUTOMATION && this.automation_group_name != AUTOMATION.RETIRE_LEAD">
            <div class="hb-font-header-3-medium pt-2">
                Basic Plan
            </div>
            <div class="ma-0 pa-0 mr-auto col-md-6 col-lg-6 col-6 pb-1 pt-2 pb-3 heading-color">
                The Basic Plan provides a one-size-fits-all basic automation.
                        </div>
            <v-expansion-panels elevation="0" class="hb-expansion-panel" v-model="setupModal_2">
                <hb-expansion-panel v-for="(automation_type, i) in BasicPlan" :key="i" :left-cols="3"
                    :right-cols="9" @click="getTimeline(automation_type, false, null,'marketing')" 
                    :class="{'active-sub-automation-header': setupModal_2 === i, 'default-sub-automation-header': setupModal_2 !== i}"
                    >
                    <template v-slot:title>
                        <div>{{automation_group_name}}</div>
                    </template>
                    <template v-slot:actions>
                        <div class="d-flex align-center action-items">
                            <div class="mx-2">
                                <hb-tooltip dashed>
                                    <template v-slot:item>
                                        {{ AUTOMATION.APPLIED_PROPERTIES_TEXT[template.toUpperCase()] }} {{
                                            automation_type.applied_properties_count }} {{ 'Property' |
                                            pluralize(automation_type.applied_properties_count) }}
                                    </template>
                                    <template v-slot:header>
                                        {{ AUTOMATION.APPLIED_PROPERTIES_TEXT.TOOLTIP_HEADER[template.toUpperCase()] }}
                                        <span v-if="isCorporateLevel">{{ automation_type.applied_properties_count }} {{
                                            'Property' | pluralize(automation_type.applied_properties_count) }}</span>
                                    </template>
                                    <template v-slot:body>
                                        <div v-for="(value, i) in automation_type.applied_properties" :key="i" class="mb-2">
                                            <div class="d-flex align-center" v-if="isCorporateLevel">
                                                <div class="mr-1">
                                                    <HbIcon :mdi-code="AUTOMATION.ICONS[value.type].icon" small
                                                        :color="AUTOMATION.ICONS[value.type].icon_color" />
                                                </div>
                                                <div v-html="AUTOMATION.ICONS[value.type].name"></div>
                                            </div>
                                            <div v-for="(property, i) in value.properties" :key="i">
                                                {{ property.number }} - {{ property.name }}
                                            </div>
                                        </div>
                                    </template>
                                </hb-tooltip>
    
                            </div>
                         
                            <div>
                                <hb-tooltip v-if="automation_type.last_modified_by || automation_type.last_modified_at">
                                    <template v-slot:item>
                                        <HbIcon mdi-code="mdi-account-clock-outline" class="mx-2" small />
                                    </template>
                                    <template v-slot:header>
                                        {{ AUTOMATION.LAST_MODIFIED }}
                                    </template>
                                    <template v-slot:body>
                                        <div>{{ automation_type.last_modified_by }}</div>
                                        <div>{{ automation_type.last_modified_at | formatLocalDateTimeServices }}</div>
                                    </template>
                                </hb-tooltip>
                            </div>
                        </div>
                    </template>
                    <template v-slot:content>
                        <template v-if="isTimelineLoading">
                            <v-row class="justify-center ma-0 pa-0">
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </v-row>
                        </template>
                        <div class="pb-6" v-else>
                            <hb-timeline v-if="timeline.length > 0">
                                <hb-timelineItem v-for="(value, i) in timeline" :key="i"
                                    :selectedAutomationType="selectedAutomationType"
                                    :background-color="value.backgroundColor"
                                    :stage-type="value.stageType"
                                    :today="value.today"
                                    :day-section-title="value.daySection.title"
                                    :day-section-title-font-color="value.daySection.titleFontColor"
                                    :day-section-description-one="value.daySection.descriptionOne"
                                    :day-section-description-two="value.daySection.descriptionTwo"
                                    :description-font-color="value.descriptionFontColor"
                                    :is-linked-stage=value.isLinkedStage
                                    :name-section-sub-title="value.nameSection.subTitle"
                                    :name-section-sub-title-description="value.nameSection.subTitleDescription"
                                    :name-section-title="value.nameSection.title"
                                    :name-section-description-one="value.nameSection.descriptionOne"
                                    :name-section-description-two="value.nameSection.descriptionTwo"
                                    :name-section-title-font-color="value.nameSection.titleFontColor"
                                    :name-section-sub-title-font-color="value.nameSection.subTitleFontColor"
                                    :action-label="value.actionLabel"
                                    :show-action-toggle="value.showActionToggle"
                                    :expand-action="value.expandAction"
                                    :action-toggle-color="value.actionToggleColor"
                                    :action-border="value.actionBorder"
                                    :action-count="value.actions?.length"
                                    :action-wrapper-class="value.actionWrapperClass"
                                    :audit="value.audit"
                                    :editable="value.editable"
                                    @delete-clicked="openDeleteModal(value, value.dependant_triggers)" @edit-clicked="editStage(value,automation_type)"
                                    @audit-mark-as-verified-clicked="handleClick('@audit-mark-as-verified-clicked', 'HbTimelineItem')"
                                    @audit-undo-clicked="handleClick('@audit-undo-clicked', 'HbTimelineItem')">
                                    <template v-if="value.actions && value.actions?.length" #action>
                                        <span v-for="(action, index) in value.actions" :key="index">
                                            <HbTimelineItemAction :type="action.type" :title="action.title"
                                                :description="action.description"
                                                :link-text="action.link && action.link.text ? action.link.text : ''"
                                                :audit-trail="action.auditTrail"
                                                :audit-trail-tooltip-name="action.auditTrailTooltipName"
                                                :audit-trail-tooltip-time="action.auditTrailTooltipTime"
                                                @link-clicked="handleClick('@link-clicked', 'HbTimelineItemAction')" />
                                            <HbTimelineItemSubAction v-for="(subAction, index) in action.subActions"
                                                :key="index" :type="subAction.type" :icon="subAction.icon"
                                                :title="subAction.title" :description="subAction.description"
                                                :icon-color="subAction.iconColor"
                                                :link-text="subAction.link && subAction.link.text ? subAction.link.text : ''"
                                                @link-clicked="handleClick('@link-clicked', 'HbTimelineItemSubAction')" />
                                        </span>
                                    </template>
                                </hb-timelineItem>
                            </hb-timeline>
                            <HbEmptyState v-else
                                class="px-6 mt-6 mx-6" 
                                :message=AUTOMATION.NO_STAGE_CREATED
                                btn-txt="Add Stage"
                                btn-color="secondary"
                                @click="handleApplyActions('addNewStage', automation_type)"
                            />
    
                            <div class="px-6">
                                <AutomationSubAccordian :automation_types="automation_type.automation_types"
                                    :template="template" />
                            </div>
                        </div>
                    </template>
                </hb-expansion-panel>
            </v-expansion-panels>
            <div class="hb-font-header-3-medium pt-3">
                Pro Plan 
                <HbIcon mdi-code="mdi-check-circle" small
                :color="getSettings.marketingAutomation == 1 && workflowAssigned ? '#02AD0F' : '#637381'"  />
                <span :style="{ 'color': getSettings.marketingAutomation == 1 && workflowAssigned ? '#000000' : '#637381' }">
                    {{ getSettings.marketingAutomation == 1 && workflowAssigned 
                        ? `Active` 
                        : 'Inactive' }}  
                </span>  
            </div>
            <v-row class="pa-0" v-if="getSettings.marketingAutomation != 1">
                <v-col class="pt-0">               
                    <div class="ma-0 pa-0 mr-auto col-md-6 col-lg-6 col-6 pb-1 pt-2 pb-3 heading-color">
                        The Pro Plan will allow you to modify the automation for all trigger scenarios..
                    </div>
                </v-col>
                <v-col cols="auto" v-if="getSettings.marketingAutomation != 1">
                    <span>
                        <hb-link @click="showProPlan = !showProPlan;" text>
                            {{ showProPlan ? 'Hide Pro Plan' : 'Show Pro Plan' }}                        </hb-link>
                    </span>
                </v-col>                
            </v-row>
            <div class="d-flex" v-else>
                <hb-switch v-if="selectedAutomationType.name == AUTOMATION.MARKETING_AUTOMATION" class="mx-2" v-model="workflowAssigned"
                @click.native.stop="(event) => $emit('toggle-event')" :readonly="true" />
                <div>
                    <div class="hb-font-header-3-medium">
                        Pro Plan
                    </div>
                    <div>
                        The Pro Plan will allow you to modify the automation for all trigger scenarios..
                    </div>
                </div>
              
            </div>          
        </div>
        <v-expansion-panels v-show="showProPlan || getSettings.marketingAutomation == 1 || (selectedAutomationType.name == AUTOMATION.DELINQUENCY_AUTOMATION)" elevation="0" class="hb-expansion-panel" v-model="setupModal_1">
            <hb-expansion-panel v-for="(automation_type, i) in currentAutomationsTypes" :key="i" :left-cols="3"
                :right-cols="9" @click="getTimeline(automation_type, false, i)" 
                :class="{'active-sub-automation-header': setupModal_1 === i, 'default-sub-automation-header': setupModal_1 !== i}"
                >
                <template v-slot:title>
                    <div>{{ selectedAutomationType.name == AUTOMATION.MARKETING_AUTOMATION ? getMarketingTitle(automation_type) : getUnitTypeTitleBasedOnLabel(automation_type.label) | capitalizeEachWord  }}</div>
                </template>
                <template v-slot:actions>
                    <div class="d-flex align-center action-items">
                        <div class="mx-2">{{ automation_type.trigger_group_stages }} {{ 'Stage' |
                            pluralize(automation_type.trigger_group_stages) }}</div>
                        <div class="mx-2">
                            <hb-tooltip dashed>
                                <template v-slot:item>
                                    {{ AUTOMATION.APPLIED_PROPERTIES_TEXT[template.toUpperCase()] }} {{
                                        automation_type.applied_properties_count }} {{ 'Property' |
                                        pluralize(automation_type.applied_properties_count) }}
                                </template>
                                <template v-slot:header>
                                    {{ AUTOMATION.APPLIED_PROPERTIES_TEXT.TOOLTIP_HEADER[template.toUpperCase()] }}
                                    <span v-if="isCorporateLevel">{{ automation_type.applied_properties_count }} {{
                                        'Property' | pluralize(automation_type.applied_properties_count) }}</span>
                                </template>
                                <template v-slot:body>
                                    <div v-for="(value, i) in automation_type.applied_properties" :key="i" class="mb-2">
                                        <div class="d-flex align-center" v-if="isCorporateLevel">
                                            <div class="mr-1">
                                                <HbIcon :mdi-code="AUTOMATION.ICONS[value.type].icon" small
                                                    :color="AUTOMATION.ICONS[value.type].icon_color" />
                                            </div>
                                            <div v-html="AUTOMATION.ICONS[value.type].name"></div>
                                        </div>
                                        <div v-for="(property, i) in value.properties" :key="i">
                                            {{ property.number }} - {{ property.name }}
                                        </div>
                                    </div>
                                </template>
                            </hb-tooltip>

                        </div>
                        <div class="mx-2 d-flex align-center">
                            <div class="mr-1">
                                <HbIcon :mdi-code="AUTOMATION.ICONS[automation_type.trigger_group_status].icon" small
                                    :color="AUTOMATION.ICONS[automation_type.trigger_group_status].icon_color" />
                            </div>
                            <div v-html="AUTOMATION.ICONS[automation_type.trigger_group_status].name" class="font-weight-medium"></div>
                        </div>
                        <div>
                            <hb-tooltip v-if="automation_type.last_modified_by || automation_type.last_modified_at">
                                <template v-slot:item>
                                    <HbIcon mdi-code="mdi-account-clock-outline" class="mx-2" small />
                                </template>
                                <template v-slot:header>
                                    {{ AUTOMATION.LAST_MODIFIED }}
                                </template>
                                <template v-slot:body>
                                    <div>{{ automation_type.last_modified_by }}</div>
                                    <div>{{ automation_type.last_modified_at | formatLocalDateTimeServices }}</div>
                                </template>
                            </hb-tooltip>
                        </div>
                        <div>
                            <hb-switch class="mx-2" v-model="automation_type.assigned"
                                @click.native.stop="(event) => toggleStatus(event, automation_type)" :readonly="true" />
                        </div>
                        <!-- this code is only used for marketing automation and later we may need it for delinquency automation.-->
                        <hb-tooltip v-if="selectedAutomationType.name == AUTOMATION.MARKETING_AUTOMATION" :disabled="!getAutomationMenuStatus(automation_type).isMenuDisabled">
                            <template v-slot:item>
                                <hb-menu options :disabled="getAutomationMenuStatus(automation_type).isMenuDisabled" >
                                    <v-list>
                                        <v-list-item v-if="getAutomationMenuStatus(automation_type).showResetToDefault" class="cursor-pointer" :ripple="false" 
                                        @click="confirmRevert(automation_type, 'Tenant')">
                                            <v-list-item-title>{{ AUTOMATION.RESET_DEFAULT }}</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item v-if="getAutomationMenuStatus(automation_type).showResetToCorporate" class="cursor-pointer" :ripple="false"
                                            @click="confirmRevert(automation_type,'Corporate')">
                                            <v-list-item-title>{{ AUTOMATION.RESET_CORPORATE }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </hb-menu>
                            </template>
                            <template v-slot:body>
                              {{ AUTOMATION.RESET_WARNING }} 
                            </template>
                        </hb-tooltip>
                    </div>

                </template>
                <template v-slot:content>
                    <template v-if="isTimelineLoading">
                        <v-row class="justify-center ma-0 pa-0">
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </v-row>
                    </template>
                    <div class="pb-6" v-else>
                        <div class="px-6" v-if="timeline.length > 0">
                            <div class="row pa-0 ma-0 d-flex align-center">
                                <div class="ma-0 pa-0 mr-auto col-md-6 col-lg-6 col-6 pb-1 pt-2 pb-3 heading-color">
                                    {{ AUTOMATION.TIMELINE_HEADING }}
                                </div>
                                <div
                                    class="d-flex ma-0 px-0 col-md-6 col-lg-6 col-6 pb-4 pt-1 pt-3 align-center justify-end">
                                    <hb-btn class="mr-2" color="secondary" prepend-icon="mdi-plus"
                                        @click="handleApplyActions('addNewStage', automation_type)" small>Add
                                        new stage</hb-btn>
                                    <!-- Commented for now but will be used later please don't remove it -->
                                    <hb-btn v-if="template === 'corporate' && automation_type.trigger_group_status === 'corporate_modified' && selectedAutomationType.name == AUTOMATION.MARKETING_AUTOMATION" class="mr-2" color="secondary"
                                        @click="handleApplyActions('otherSpaceTypes', automation_type)" small>Apply to other workflow scenarios</hb-btn>
                                    <hb-menu v-if="template === 'property' && automation_type.trigger_group_status === 'property_modified' && selectedAutomationType.name == AUTOMATION.MARKETING_AUTOMATION" class="mr-2">
                                        <template v-slot:menu-activator>
                                            <hb-btn color="secondary" append-icon="mdi-menu-down" small>Apply
                                                to</hb-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item v-for="(option, index) in applyOptions" :key="'option' + index"
                                                @click="handleApplyActions(option.action, automation_type)"
                                                :ripple="false">
                                                <v-list-item-title>{{ option.title }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </hb-menu>
                                    <HbIcon :mdi-code="toggleAllActions ? 'mdi-arrow-expand-vertical' : 'mdi-arrow-collapse-vertical'"
                                        @click="handleApplyActions('expandActions', automation_type)" :pointer="true" />
                                </div>
                            </div>
                        </div>
                        <hb-timeline v-if="timeline.length > 0">
                            <hb-timelineItem v-for="(value, i) in timeline" :key="i"
                                :name-section-title-icon="value.isAnchorStage ? 'mdi-anchor' : ''"
                                name-section-title-icon-color="#101318"
                                :name-section-title-icon-tooltip-body="AUTOMATION.PAYMENT_REVERSAL_TOOLTIP"
                                :selectedAutomationType="selectedAutomationType"
                                :background-color="value.backgroundColor"
                                :stage-type="value.stageType"
                                :today="value.today"
                                :day-section-title="value.daySection.title"
                                :day-section-title-font-color="value.daySection.titleFontColor"
                                :day-section-description-one="value.daySection.descriptionOne"
                                :day-section-description-two="value.daySection.descriptionTwo"
                                :description-font-color="value.descriptionFontColor"
                                :is-linked-stage=value.isLinkedStage
                                :is-paid-to-date-stage="value.isPTODStage"
                                :name-section-sub-title="value.nameSection.subTitle"
                                :name-section-sub-title-description="value.nameSection.subTitleDescription"
                                :name-section-title="value.nameSection.title"
                                :name-section-description-one="value.nameSection.descriptionOne"
                                :name-section-description-two="value.nameSection.descriptionTwo"
                                :name-section-title-font-color="value.nameSection.titleFontColor"
                                :name-section-sub-title-font-color="value.nameSection.subTitleFontColor"
                                :action-label="value.actionLabel"
                                :show-action-toggle="value.showActionToggle"
                                :expand-action="value.expandAction"
                                :action-toggle-color="value.actionToggleColor"
                                :action-border="value.actionBorder"
                                :action-count="value.actions?.length"
                                :action-wrapper-class="value.actionWrapperClass"
                                :audit="value.audit"
                                :editable="value.editable"
                                :timelineTag="value.timelineTag"
                                @delete-clicked="openDeleteModal(value, value.dependant_triggers)" @edit-clicked="editStage(value,automation_type)"
                                @audit-mark-as-verified-clicked="handleClick('@audit-mark-as-verified-clicked', 'HbTimelineItem')"
                                @audit-undo-clicked="handleClick('@audit-undo-clicked', 'HbTimelineItem')">
                                <template v-if="value.actions && value.actions?.length" #action>
                                    <span v-for="(action, index) in value.actions" :key="index">
                                        <HbTimelineItemAction :type="action.type" :title="action.title"
                                            :description="action.description"
                                            :link-text="action.link && action.link.text ? action.link.text : ''"
                                            :audit-trail="action.auditTrail"
                                            :audit-trail-tooltip-name="action.auditTrailTooltipName"
                                            :audit-trail-tooltip-time="action.auditTrailTooltipTime"
                                            @link-clicked="handleClick('@link-clicked', 'HbTimelineItemAction')" />
                                        <HbTimelineItemSubAction v-for="(subAction, index) in action.subActions"
                                            :key="index" :type="subAction.type" :icon="subAction.icon"
                                            :title="subAction.title" :description="subAction.description"
                                            :icon-color="subAction.iconColor"
                                            :link-text="subAction.link && subAction.link.text ? subAction.link.text : ''"
                                            @link-clicked="handleClick('@link-clicked', 'HbTimelineItemSubAction')" />
                                    </span>
                                </template>
                            </hb-timelineItem>
                        </hb-timeline>
                        <HbEmptyState v-else
                            class="px-6 mt-6 mx-6" 
                            :message=AUTOMATION.NO_STAGE_CREATED
                            btn-txt="Add Stage"
                            btn-color="secondary"
                            @click="handleApplyActions('addNewStage', automation_type)"
                        />

                        <div class="px-6">
                            <AutomationSubAccordian :automation_types="automation_type.automation_types"
                                :template="template" />
                        </div>
                    </div>
                </template>
            </hb-expansion-panel>
        </v-expansion-panels>
        <workflow-toggle v-model="showTypesToggleModal" :workflow="automationToToggle" :template="template" :selectedAutomationType="selectedAutomationType" :disabled="showLoader"
            v-if="showTypesToggleModal" @close="confirmStatus()" @ok="confirmStatus(true)">
        </workflow-toggle>
        <apply-workflow v-model="showApplyWorkflowModal" :title="title" :sub_title="sub_title"
            :description="description" :actions="actions" :disabled="showLoader" v-if="showApplyWorkflowModal"
            @close="confirmStatus()" @ok="confirmStatus(true)" @applyActions="applyActions" :flow_type="flowType">
        </apply-workflow>
        <delete-stage-action-warning v-model="show_delete_stage" :disabled="deleteStageLoader"
            v-if="show_delete_stage" :allow_to_delete_trigger="allow_to_delete_trigger"  @close="closeDeleteStage()" @confirmDeleteStage="confirmDeleteStage()">
        </delete-stage-action-warning>
        <payments-notification-modal v-model="notificationModal" notificationType="manage_delinquency_stages"></payments-notification-modal>       
        <revert-back-modal v-model="showRevertBackModal" :title="revertAutomationData?.name" :type="revertAutomationData?.type" @close="resetToDefault()" @ok="resetToDefault(true)"></revert-back-modal>
    </div>
</template>
<script type="text/babel">
import { mapGetters, mapActions } from "vuex";
import WorkflowToggle from './WorkflowToggleModal.vue';
import AutomationSubAccordian from './AutomationSubAccordian.vue'
import ApplyWorkflow from './ApplyWorkflowModal.vue'
import DeleteStageActionWarning from './DeleteStageActionWarningModal.vue';
import AUTOMATION from '@/constants/automation.js';
import api from "../../../assets/api.js";
import { EventBus } from "../../../EventBus.js";
import { notificationMixin } from '@/mixins/notificationMixin.js';
import PaymentsNotificationModal from '../../payments/PaymentsNotificationModal.vue';
import RevertBackModal from "./RevertBackModal.vue";
export default {
    name: "AutomationSubAccordian",
    mixins: [notificationMixin],
    data() {
        return {
            setupModal: [],
            setupModal_1: [],
            setupModal_2:[],
            isTimelineLoading: false,
            timeline: [],
            showTypesToggleModal: false,
            showApplyWorkflowModal: false,
            automationToToggle: {},
            applyOptions: [
                {
                    title: 'Other workflow scenarios',
                    action: 'otherWorkFlow'
                },
                {
                    title: 'Other properties',
                    action: 'otherProperties'
                }
            ],
            title: '',
            sub_title: '',
            description: '',
            actions: [],
            selectedActions: [],
            currentAutomationsTypes: [],
            showLoader: false,
            show_delete_stage: false,
            stages: [],
            selectedAutomation: null,
            toggleAllActions: false,
            deleteStageLoader: false,
            selected_automation_group_index: null,
            notificationModal: false,
            allow_to_delete_trigger: true,
            BasicPlan:[],
            showProPlan:false,
            showRevertBackModal:false,
            revertAutomationData:{},
            flowType: '',
        };
    },
    components: {
        WorkflowToggle,
        AutomationSubAccordian,
        ApplyWorkflow,
        PaymentsNotificationModal,
        DeleteStageActionWarning,
        RevertBackModal
    },
    props: {
        workflowAssigned:{
                type:Number
        },
        automation_types: {
            type: Array,
            default() {
                return [];
            }
        },
        template: {
            type: String,
            default() {
                return 'corporate';
            }
        },
        selectedPropertyId: {
            type: String || null,
            default() {
                return null;
            }
        },
        selectedAutomationType: {
            type: Object,
            default: () => ({})
        },
        parent_automation_group_id: {
            type: String || null,
            default() {
                return null;
            }
        },
        automation_group_name:{
            type: String || null,
            default() {
                return null;
            }
        },
    },
    created() {
        this.AUTOMATION = AUTOMATION;
        EventBus.$on('refresh', this.refreshWorkflow);
        EventBus.$on('propertyChanged',this.updateCurrentTimeline);
        this.BasicPlan = JSON.parse(
        JSON.stringify(this.automation_types.filter(e => e.category == 'basic'))
    );
    },
    computed: {
        ...mapGetters({
            currentTimeline: "automationStore/getCurrentTimeLine",
            hasPermission: 'authenticationStore/rolePermission',
            getSettings: 'authenticationStore/getSettings',
        }),
        isCorporateLevel() {
            return this.template === AUTOMATION.MENU_TABS.CORPORATE;
        },
        hasDelinquencyEditPermission(){
            return this.hasPermission("manage_delinquency_stages");
        }    
    },
    methods: {
        ...mapActions({
            fetchAllWorkflows: 'automationStore/fetchAllWorkflows',
            setNotification: 'automationStore/setNotification'
        }),
        toggleStatus(e, automation) {
            e.preventDefault();
            if(!this.hasDelinquencyEditPermission) {
                this.notificationModal = true;
                return false;
            } 
            this.showTypesToggleModal = true;
            this.automationToToggle = { ...automation };
        },
        getMarketingTitle(data){
           let temp = data.full_path.replace(/>\s*(\w+)/, (match, p1) => `> ${p1.charAt(0).toUpperCase() + p1.slice(1)}`);
           return temp + ' > ' + data.origin
        },
        async confirmStatus(confirm = false) {
            if (confirm) {
                if(this.showApplyWorkflowModal){
                    if(this.flowType == 'workflow'){
                        let automation_group_ids = this.selectedActions.map(action => {
                            if(action.active) {
                                return action.id
                            }
                        }).filter(id => id != null)
                        
                        let params = {
                            corporate_level: this.template === 'corporate' ? true : false,
                            automation_group_id: this.selectedAutomation,
                            property_id: this.selectedPropertyId,
                            automation_group_ids
                            
                        }
                        let response = await api.put(this, `${api.AUTOMATION}/apply-other-flows`, params);
                        if (response) {
                            await this.fetchAllWorkflows({
                                automation_type_id: this.selectedAutomationType.id,
                                property_id: this.selectedPropertyId
                            });
                        }
                        this.showTypesToggleModal = false;
                        this.showApplyWorkflowModal = false;
                        this.showLoader = false;
                        this.setNotification({type:'success', msg: `Automation has been applied successfully ot other flows`});
                    }else{
                        let property_ids = this.selectedActions.map(action => action.id);
                        let params = {
                            corporate_level: false,
                            automation_group_id: this.selectedAutomation,
                            property_id: this.selectedPropertyId,
                            property_ids,
                        };
                        
                        let response = await api.put(this, `${api.AUTOMATION}/apply-flow-property`, params);
                        if (response) {
                            await this.fetchAllWorkflows({
                                automation_type_id: this.selectedAutomationType.id,
                                property_id: this.selectedPropertyId
                            });
                        }
                        this.showTypesToggleModal = false;
                        this.showApplyWorkflowModal = false;
                        this.showLoader = false;
                        this.setNotification({type:'success', msg: `Automation has been applied successfully ot other properties`});
                    }
                }else{
                    this.showLoader = true;
                    let params = {
                        unit_type_labels: [this.automationToToggle.label],
                        property_id: this.selectedPropertyId,
                        automation_group_ids: [this.automationToToggle.id],
                        parent_automation_group_id: this.parent_automation_group_id,
                    }
                    let endPointName = this.automationToToggle.assigned ? `pause` : `resume`;
                    try {
                        let response = await api.put(this, `${api.AUTOMATION}/${this.selectedAutomationType.id}/${endPointName}`, params);
                        if (response) {
                            await this.fetchAllWorkflows({
                                automation_type_id: this.selectedAutomationType.id,
                                property_id: this.selectedPropertyId
                            });
                        }
                        this.showTypesToggleModal = false;
                        this.showApplyWorkflowModal = false;
                        this.showLoader = false;
                        this.setNotification({type:'success', msg: `Automation has been successfully ${this.automationToToggle.assigned ? 'Paused.' : 'Resumed.'}`});
                    } catch (err) {
                        this.showLoader = false;
                        this.setNotification({type:'error', msg: err});
                    }
                }

            } else {
                this.showTypesToggleModal = false;
                if(this.showApplyWorkflowModal){
                    this.showApplyWorkflowModal = false;
                    this.actions = [];
                } 
            }
        },
        async getTimeline(automation_type, toggleAction = false, index,type) {
            if (index !== -1) {
                if ((index != null || index != undefined) && index != this.selected_automation_group_index) {
                    this.selected_automation_group_index = index;
                    this.setupModal_2 = []
                }
                else {
                    this.selected_automation_group_index = null;
                    if(type == 'marketing'){
                        this.setupModal_1 = []
                    }
                }
                this.toggleAllActions = false;
            }
            this.isTimelineLoading = true;
            try {
                if (!automation_type?.trigger_group_id) 
                    throw new Error("No trigger_group_id found");

                let response = await api.get(this, api.TRIGGERS + "?trigger_group_id=" + automation_type.trigger_group_id + `&automation_category_id=${this.selectedAutomationType.automation_category_id}`);
                this.stages = response.triggers.length ? response.triggers : [];
                this.mapTriggers(response, automation_type, toggleAction);
                this.isTimelineLoading = false;
            } catch (err) {
                this.isTimelineLoading = false;
                this.setNotification({type:'error', msg: err});
            }
        },
        mapTriggers(response, automation_type, toggleAction = false) {
            this.timeline = [];
            if (response && response.triggers && response.triggers.length > 0) {
                let allActions = response.triggers.reduce((allActions, trigger) => {
                    if (trigger.Actions && trigger.Actions.length > 0) {
                        allActions = [...allActions, ...trigger.Actions];
                    }
                    return allActions;
                }, []);

                this.$store.commit('automationStore/setAllTriggers', response.triggers || []); 
                
                this.timeline = response.triggers.map((trigger, index) => ({
                    stageType: "default",
                    timelineTag: this.selectedAutomationType.name == AUTOMATION.MARKETING_AUTOMATION ? ((trigger.trigger_reference == "before_move_in" ? 'Before Move In Date' : (`After ${this.automation_group_name}`)))  :'',
                    daySection: {
                        title: this.selectedAutomationType.name == AUTOMATION.MARKETING_AUTOMATION ? ( trigger.execution_day == 0 ? ((trigger?.start_hours || trigger?.start_minutes) ? `${trigger?.start_hours} hours ${trigger?.start_minutes} minutes` : 'Immediately') : `${trigger.execution_day ==1 ? `${trigger.execution_day} Day` : `${trigger.execution_day} Days` }`) :`Day ${trigger.execution_day}`,
                        titleFontColor: "",
                        descriptionOne:this.selectedAutomationType.name == AUTOMATION.MARKETING_AUTOMATION ? ((trigger.trigger_reference == "before_move_in" ? 'Before Move In Date' : (`After ${this.automation_group_name}`)))  :''
                    },
                    nameSection: {
                        title: trigger.name,
                        subTitle: trigger?.execution_timing_label || "",
                        subTitleDescription: trigger?.parent_action_name
                            ? `${trigger?.parent_action_name} ${allActions.find(action => action.id === trigger.parent_action_id)?.description
                                ? `(${allActions.find(action => action.id === trigger.parent_action_id).description})`
                                : ''
                            }`
                            : '',
                        descriptionOne: trigger.description,
                        titleFontColor: "",
                        subTitleFontColor: "",
                    },
                    isLinkedStage: !!trigger.parent_action_id,
                    isPTODStage: trigger.trigger_action_dependency_type_slug === AUTOMATION.AFTER_PTOD ? true : false,
                    isAnchorStage: trigger.is_anchor_stage ? true : false,
                    showActionToggle: true,
                    expandAction: toggleAction ? false : true,
                    trigger_id: trigger.id,
                    trigger_group_id: automation_type.trigger_group_id,
                    automation_group_id: automation_type.id,
                    actionBorder: true,
                    today: false,
                    actionWrapperClass: "pa-3",
                    backgroundColor: "#FFFFFF",
                    actionLabel: this.$options.filters.pluralize('Action', trigger.Actions.length),
                    actionToggleColor: "primary",
                    descriptionFontColor: "",
                    audit: false,
                    actions: trigger.Actions.map(action => {
                        const subActions = [];
                        if (action.DeliveryMethods && action.DeliveryMethods.length > 0) {
                            const methodNames = action.DeliveryMethods.map(method => method.method_name).join(', ');
                            subActions.push({
                                type: 'default',
                                icon: 'mdi-email-outline',
                                title: 'Delivery Methods',
                                description: methodNames,
                                iconColor: '#637381',
                                linkText: '',
                            });
                        }
                        if(action.Events.length && this.selectedAutomationType.name == AUTOMATION.MARKETING_AUTOMATION){
                            subActions.push({
                                type: 'default',
                                icon: 'mdi-clipboard-text',
                                title: action.Events[0].EventType.name,
                                description: action.Events[0].details,
                                iconColor: '#637381',
                                linkText: '',
                            });
                        }
                        return {
                            type: 'default',
                            title: action.name,
                            description: action.description,
                            linkText: action.linkText || "View Action",
                            auditTrail: false,
                            auditTrailTooltipName: action.auditTrailTooltipName || "N/A",
                            auditTrailTooltipTime: action.auditTrailTooltipTime || "N/A",
                            subActions,
                        };
                    }),
                    dependant_triggers: trigger.dependant_triggers,
                    editable: (this.getSettings.marketingAutomation != 1 && this.selectedAutomationType.name == AUTOMATION.MARKETING_AUTOMATION)  ? false : true
                }));
            }
            if(this.selectedAutomationType.name == AUTOMATION.MARKETING_AUTOMATION){
                let data = {
                stageType: "default",
                daySection: {
                    title: AUTOMATION.DAY_0_MARKETING,
                },
                nameSection: {
                    title: AUTOMATION.DAY_0_STAGE_TITLE_MARKETING,
                    descriptionOne:AUTOMATION.DAY_0_STAGE_DESCRIPTION_MARKETING
                },
                "showActionToggle": true,
                "expandAction": toggleAction ? false : true,
                "actionWrapperClass": "pa-3",
                "backgroundColor": "#FFFFFF",
                "actionLabel": "Actions",
                "actionToggleColor": "primary",
                "actionBorder": true,
                actions: [],
                editable: false
            }
            if(this.automation_group_name != AUTOMATION.PENDING_MOVE_IN && this.automation_group_name != AUTOMATION.RETIRE_LEAD){
                data.actions.push({
                        "type": "default",
                        "title": "Send Message",
                        "description": "Email > Confirmation Email",
                        "linkText": "View Action",
                        "auditTrail": false,
                        "auditTrailTooltipName": "N/A",
                        "auditTrailTooltipTime": "N/A",
                        "subActions": []
                    }
                   )  
            }
            if(!(this.automation_group_name == AUTOMATION.PENDING_MOVE_IN || this.automation_group_name == AUTOMATION.CART_ABANDONMENT || this.automation_group_name == AUTOMATION.WEBSITE_RENTAL_FAILURE ||  this.automation_group_name== AUTOMATION.NEW_LEAD) && this.automation_group_name != AUTOMATION.RETIRE_LEAD){
                data.actions.push( {
                        "type": "default",
                        "title": "Send Message",
                        "description": "Text > Confirmation Text",
                        "linkText": "View Action",
                        "auditTrail": false,
                        "auditTrailTooltipName": "N/A",
                        "auditTrailTooltipTime": "N/A",
                        "subActions": []
                    }
                   )  
            }
            if(!(this.automation_group_name == AUTOMATION.RENTAL ||  this.automation_group_name== AUTOMATION.NEW_LEAD) && this.automation_group_name != AUTOMATION.RETIRE_LEAD){
                data.actions.push({
                        "type": "default",
                        "title": "Create Task",                        
                        "linkText": "View Action",
                        "auditTrail": false,
                        "auditTrailTooltipName": "N/A",
                        "auditTrailTooltipTime": "N/A",
                        "subActions": [],
                        "description":"Task Created For User"
                    })
            }         
                this.timeline.unshift(data);
                
            }else if(this.selectedAutomationType.name == AUTOMATION.DELINQUENCY_AUTOMATION){
                this.timeline.unshift({
                stageType: "default",
                daySection: {
                    title: AUTOMATION.DAY_0,
                },
                nameSection: {
                    title: AUTOMATION.DAY_0_STAGE_TITLE,
                    descriptionOne: AUTOMATION.DAY_0_STAGE_DESCRIPTION
                },
                editable: false
            });
            }
           
          
        },
        handleApplyActions(action, automation_type) {
            this.actions = [];
            switch (action) {
                case 'otherWorkFlow':
                    this.showApplyWorkflowModal = true;
                    this.selectedAutomation = automation_type.id;
                    this.title = 'Apply to other workflow scenarios';
                    this.sub_title = this.selectedAutomationType.name == AUTOMATION.MARKETING_AUTOMATION ? this.getMarketingTitle(automation_type) : this.getUnitTypeTitleBasedOnLabel(automation_type.label);
                    this.description = 'Select other workflow scenarios to apply this workflow to. This will replace the entire workflows with current one.';
                    this.flowType = 'workflow';
                    this.currentAutomationsTypes.map(item => {
                        if(item.id != automation_type.id) {
                            this.actions.push({
                                id: item.id,
                                name: this.selectedAutomationType.name == AUTOMATION.MARKETING_AUTOMATION ? this.getMarketingTitle(item) : this.getUnitTypeTitleBasedOnLabel(item.label),
                                process_name: AUTOMATION.ICONS[item.trigger_group_status].name,
                                icon_context: AUTOMATION.ICONS[item.trigger_group_status].icon_color,
                                icon: AUTOMATION.ICONS[item.trigger_group_status].icon,
                                active: false
                            })
                        }
                    })
                    break;
                case 'otherProperties':
                    this.showApplyWorkflowModal = true;
                    this.selectedAutomation = automation_type.id;
                    this.title = 'Apply to other properties';
                    this.sub_title = this.selectedAutomationType.name == AUTOMATION.MARKETING_AUTOMATION ? this.getMarketingTitle(automation_type) : this.getUnitTypeTitleBasedOnLabel(automation_type.label);
                    this.description = 'Select properties to assign this workflow to. Selecting a property that has modified this workflow will override it and replace it with this one.';
                    this.flowType = 'properties';
                    this.actions = automation_type.applied_properties[0].properties
                    break;
                case "addNewStage":
                    this.selectedAutomation = automation_type.id;
                    EventBus.$emit('openStageDrawer', {
                        automation_group_id: automation_type.id,
                        trigger_group_id: automation_type.trigger_group_id,
                        property_id: this.selectedPropertyId,
                        automation: automation_type,
                        selectedAutomationType:this.selectedAutomationType
                    });
                    break;
                case 'expandActions':
                    this.toggleAllActions = !this.toggleAllActions;
                    this.getTimeline(automation_type, this.toggleAllActions, -1);
                    break;
                case 'otherSpaceTypes':
                    this.showApplyWorkflowModal = true;
                    this.selectedAutomation = automation_type.id;
                    this.title = 'Apply to other workflow scenarios';
                    this.sub_title = this.selectedAutomationType.name == AUTOMATION.MARKETING_AUTOMATION ? this.getMarketingTitle(automation_type) : this.getUnitTypeTitleBasedOnLabel(automation_type.label);
                    this.description = 'Select other workflow scenarios to apply this workflow to. This will replace the entire workflows with current one.';
                    this.flowType = 'workflow';
                    this.currentAutomationsTypes.map(item => {
                        if(item.id != automation_type.id) {
                            this.actions.push({
                                id: item.id,
                                name: this.selectedAutomationType.name == AUTOMATION.MARKETING_AUTOMATION ? this.getMarketingTitle(item) : this.getUnitTypeTitleBasedOnLabel(item.label),
                                process_name: AUTOMATION.ICONS[item.trigger_group_status].name,
                                icon_context: AUTOMATION.ICONS[item.trigger_group_status].icon_color,
                                icon: AUTOMATION.ICONS[item.trigger_group_status].icon,
                                active: false
                            })
                        }
                    })
                    break;
                default:
                    console.log('default!!')
            }
        },
        applyActions(actions) {
            this.selectedActions = actions;
        },
        openDeleteModal(data, triggers) {
            this.selected_stage = data;
            this.show_delete_stage = true;
            this.allow_to_delete_trigger = triggers.length ? false : true;
        },
        async confirmDeleteStage() {
            this.selectedAutomation = this.selected_stage.automation_group_id;
            this.deleteStageLoader = true;
            try {
                await api.delete(this, `${api.TRIGGERS}${this.selected_stage.trigger_id}?property_id=${this.selectedPropertyId}&automation_group_id=${this.selected_stage.automation_group_id}`);
                await this.fetchAllWorkflows({
                    automation_type_id: this.selectedAutomationType.id,
                    property_id: this.selectedPropertyId
                });
                this.refreshWorkflow(this.selectedAutomation);
                this.deleteStageLoader = false;
                this.setNotification({type:'success', msg: `Automation stage has been successfully deleted!`});
            } catch (error) {
                this.deleteStageLoader = false;
                this.setNotification({type:'error', msg: error});
            }

            this.closeDeleteStage();
        },
        closeDeleteStage() {
            this.show_delete_stage = false;
        },
        editStage(event, automation) {
            if(!this.hasDelinquencyEditPermission) {
                this.notificationModal = true;
                return false;
            } 
            const stage = this.stages.find(stage => stage.id === event.trigger_id);
            this.selectedAutomation = event.automation_group_id;
            if (stage) {
                EventBus.$emit('openStageDrawer', {
                    automation_group_id: event.automation_group_id,
                    trigger_group_id: event.trigger_group_id,
                    property_id: this.selectedPropertyId,
                    stage: stage,
                    automation: automation
                });
            }
        },
        refreshWorkflow(data) {
            if (data === this.selectedAutomation) {
                const selectedAutomationValue = this.currentAutomationsTypes.find(
                    automation => automation.id === data
                );
                this.getTimeline(selectedAutomationValue, false, -1);
            }
        },
        async updateCurrentTimeline() {
            var selected_automation_group = this.currentAutomationsTypes[this.selected_automation_group_index];
            if (selected_automation_group)
                await this.getTimeline(selected_automation_group, false, -1);
        },
        async resetToDefault(confirm){
            if(confirm) {
                let data = {
                    automation_group_id: this.revertAutomationData.id,
                    property_id: this.selectedPropertyId,
                    is_corporate_default: this.revertAutomationData.type == 'Corporate' ? true : false
                }
                try {
                    let response = await api.put(this, `${api.AUTOMATION}/reset`, data);
                    if (response) {
                        await this.fetchAllWorkflows({
                            automation_type_id: this.selectedAutomationType.id,
                            property_id: this.selectedPropertyId
                        });
                        await this.updateCurrentTimeline();
                        let message = data?.is_corporate_default ? AUTOMATION.RESET_SUCCESS_MESSAGE.CORPORATE_MODIFIED : AUTOMATION.RESET_SUCCESS_MESSAGE.TENANT_PROCESS;
                        this.setNotification({ type: 'success', msg: message });
                        this.showRevertBackModal = false;
                    }
                }
                catch (error) {
                    this.setNotification({type:'error', msg: error});
                }
            }else {
                this.showRevertBackModal = false;
                this.revertAutomationData = {};
            }
        },
        getAutomationMenuStatus(automation) {
            const showResetToCorporate = (!this.isCorporateLevel && automation.trigger_group_status !== this.AUTOMATION.STATUSES.CORPORATE_MODIFIED && automation.company_trigger_group_status !==this.AUTOMATION.STATUSES.TENANT_PROCESS );
            const showResetToDefault = (automation.trigger_group_status !== this.AUTOMATION.STATUSES.TENANT_PROCESS);
            const isMenuDisabled = (!showResetToCorporate && !showResetToDefault);

            return {
                isMenuDisabled,
                showResetToCorporate,
                showResetToDefault
            };
        },
        confirmRevert(data, type){
            this.revertAutomationData = {
                id : data.id,
                type : type,
                name : this.getMarketingTitle(data),
            }
            this.showRevertBackModal = true;
        },
    },
    watch: {
        automation_types: {
            handler() {
                this.currentAutomationsTypes = [...this.automation_types.filter(e=> e.category !== 'basic' )];
            },
            immediate: true
        },
    },

    destroyed() {
        EventBus.$off('refresh', this.refreshWorkflow);
        EventBus.$off('propertyChanged',this.updateCurrentTimeline);
    },
};
</script>
<style scoped>
.action-items {
    font-size: 14px;
}

.heading-color {
    color: rgb(99, 115, 129);
}
.no_stages{
    text-align: center;
    color: rgba(0, 0, 0, 0.87)
}
</style>
<style>
    .default-sub-automation-header .v-expansion-panel-header{
        background:#F9FAFB !important;
    }
    .default-sub-automation-header{
        border-color : #DFE3E8 !important;
    }
    .active-sub-automation-header .v-expansion-panel-header{
        background:#E0F5F5 !important;
    }
    .active-sub-automation-header{
        border-color : #00848E !important;
    }
</style>